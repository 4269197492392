//Displayed on navbar:
import {Frontpage} from 'pages/Frontpage';
import {ComponentType} from 'react';
import CustomerContactPersons from "./CustomerContactPersons";
import PetrolStations from "./PetrolStations";
import Tanks from "./Tanks";
import Agreements from "./Agreements";
import Users from "./Users";
import {Login} from "./Login";
import ElectricityPackages from "./ElectricityPackages";
import ElectricityAgreements from "./ElectricityAgreements";
import TermsAndConditions from './TermsAndConditions';
import LoyaltyCampaigns from './LoyaltyCampaigns';
import SpecialOffers from './SpecialOffers';
import Advertisements from './Advertisements';
import ElectrcityInvoices from './ElectricityInvoices';
import ElectricityCustomers from './ElectricityCustomers';
import ChargingPricings from './ChargingPricings';
import ChargingAgreements from './ChargingAgreements';
import EnergyPackages from "./EnergyPackages";

interface IPages {
    title: string;
    component: ComponentType<any>;
    showInNav: boolean;
    isUtility: boolean;
    isPublic: boolean
}

export const Pages: IPages[] = [
    {title: 'login', component: Login, showInNav: false, isUtility: true, isPublic: true},
    {title: 'esileht', component: Frontpage, showInNav: false, isUtility: false, isPublic: false},
    {title: 'tanklad', component: PetrolStations, showInNav: true, isUtility: false, isPublic: false},
    {title: 'mahutid', component: Tanks, showInNav: true, isUtility: false, isPublic: false},
    {title: 'elektripaketid', component: ElectricityPackages, showInNav: true, isUtility: false, isPublic: false},
    {title: 'energiapaketid', component: EnergyPackages, showInNav: true, isUtility: false, isPublic: false},
    {title: 'elektrilepingud', component: ElectricityAgreements, showInNav: true, isUtility: false, isPublic: false},
    {title: 'elektriarved', component: ElectrcityInvoices, showInNav: true, isUtility: false, isPublic: false},
    {title: 'elektrikliendid', component: ElectricityCustomers, showInNav: true, isUtility: false, isPublic: false},
    {title: 'tüüptingimused', component: TermsAndConditions, showInNav: true, isUtility: false, isPublic: false},
    {title: 'kliendikontaktid', component: CustomerContactPersons, showInNav: true, isUtility: false, isPublic: false},
    {title: 'lepingud', component: Agreements, showInNav: true, isUtility: false, isPublic: false},
    {title: 'lojaalsuskampaaniad', component: LoyaltyCampaigns, showInNav: true, isUtility: false, isPublic: false},
    {title: 'eripakkumised', component: SpecialOffers, showInNav: true, isUtility: false, isPublic: false},
    {title: 'reklaamid', component: Advertisements, showInNav: true, isUtility: false, isPublic: false},
    {title: 'logi', component: Frontpage, showInNav: false, isUtility: false, isPublic: false},
    {title: 'kasutajad', component: Users, showInNav: true, isUtility: false, isPublic: false},
    {title: 'laadimishinnakirjad', component: ChargingPricings, showInNav: true, isUtility: false, isPublic: false},
    {title: 'laadimislepingud', component: ChargingAgreements, showInNav: true, isUtility: false, isPublic: false},
];
