import React, {useState} from 'react';
import {Link, NavLink} from 'react-router-dom';

import { Pages } from 'pages/pages';
import {InitContextProps, useAuthContext} from "../../services/context/authentication.context";
import {MobileMenu} from "../MobileMenu/MobileMenu";
import { AuthenticationService } from 'services/authentication.service';

export function Header(props: { utilityPageState: boolean}) {
    const {dispatch}: InitContextProps = useAuthContext();
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const toggleMobileMenu = () => setMobileMenuOpen(!isMobileMenuOpen);
    const handleLogout = async () => {
        await AuthenticationService.logout();
        dispatch({
            type: "setAuthenticated",
            value: false
        });
        
    };

    const checkIfUtility = () => {
        if (props.utilityPageState) {
            return (
                <a
                    className='d-flex justify-content-end text-primary mr-xl-5 line-height-1 '
                    href='https://www.terminalenergia.ee/'
                    target='_blank'
                    rel="noopener noreferrer">
                    <div className='d-flex align-items-center'>
                        Terminali kodulehele
                        <div className='ml-1'>
                        </div>
                        <div className='icon--16 icon__external-link'/>
                    </div>
                </a>
            )
        } else {
            return (
                <div className="d-flex flex-row flex-wrap justify-content-end align-items-center">
                    <div className="d-lg-none">
                    </div>
                    {/* <button
                        type='button'
                        className="btn btn-admin-primary d-flex align-items-center h-100"
                    >
                        <i className="icon--16 icon__bell" />
                        <span className='d-none d-xl-inline'>
                        </span>
                    </button>
                    <div className='d-flex align-items-center d-lg-none mx-1'>
                        <div className='border-left border-success pt-3' />
                    </div> */}
                    <button
                        type='button'
                        className='btn btn-admin-primary d-flex d-lg-none align-items-center'
                        onClick={toggleMobileMenu}
                    >
                        <i className="icon--16 icon__mobile-menu" />
                    </button>
                    <div className='d-flex align-items-center d-lg-none mx-1'>
                        <div className='border-left border-success pt-3' />
                    </div>
                    <Link to='/login'>
                        <button
                            type='button'
                            className='btn btn-admin-primary d-flex align-items-center'
                            onClick={handleLogout}>
                        <span>
                            Logi välja
                        </span>
                        </button>
                    </Link>
                </div>
            )}
    };

    const navStyles = props.utilityPageState
        ? 'd-none'
        : 'd-none ml-5 ml-xl-0 d-lg-block col';

    const populateNav = Pages.filter((page) => page.showInNav).map((page) =>
        <li key={page.title} className="px-1 px-xl-3">
            <NavLink
                to={`/${page.title}`}
                className='text-white text-capitalize text-decoration-none'
                activeClassName='active'>
                {page.title}
            </NavLink>
        </li>);

    return (
        <header
            className={props.utilityPageState ? 'container-fluid utilityPage' : 'container-fluid'}
        >
            <div className="row w-100">
                <div className="header__logo col-sm ml-xl-5 w-8-rem header-side-container"/>
                <nav className={navStyles}>
                    <ul className='nav d-flex justify-content-center'>
                        {populateNav}
                    </ul>
                </nav>
                <div className='col mx-n2 mx-sm-0 px-0 px-sm-3 text-right header-side-container'>
                    {checkIfUtility()}
                </div>
            </div>
            <MobileMenu
                isOpen={isMobileMenuOpen}
                action={toggleMobileMenu}
                handleLogout={handleLogout}
            />
        </header>
    )
}
