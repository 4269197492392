import React, {useState} from 'react';
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import {EditTermsAndConditionsConfirmModal} from "./EditTermsAndConditionsConfirmModal";
import {
    ITermsAndConditionsConfirmModalProps,
    ITermsAndConditionsForm
} from "../models";
import {ModalType} from "../../../common/common-models";
import { TermsAndConditionsService } from '../TermsAndConditions.service';

export function EditTermsAndConditionsModal(props: ITermsAndConditionsConfirmModalProps) {
    const [saving, setSaving]: [boolean, any] = useState(false);
    const [savingError, setSavingError]: [boolean, any] = useState(false);
    const [validated, setValidated]: [boolean, any] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible]: [boolean, any] = useState(false);
    const latestVersion = props.editingTermsAndConditions?.latestVersion;
    const [form, setForm]: [ITermsAndConditionsForm, any] = useState({
        name: props.editingTermsAndConditions ? props.editingTermsAndConditions.name : "",
        confirmationText: props.editingTermsAndConditions ? props.editingTermsAndConditions.confirmationText : "",
        loyaltyConfirmationText: props.editingTermsAndConditions ? props.editingTermsAndConditions.loyaltyConfirmationText : "",
        latestVersion: {
            id: latestVersion?.id,
            privateClientLink: latestVersion?.privateClientLink ?? "",
            businessClientLink: latestVersion?.businessClientLink ?? "",
            version: latestVersion?.version ?? undefined,
            agreementWithdrawalConditions: latestVersion?.agreementWithdrawalConditions ?? "",
            privateClientLinkUpdateSummary: "",
            businessClientLinkUpdateSummary: ""
        }      
    }); 

    const validateForm = () => {
        if (form.name.trim().length === 0)
            return false;  
        
        if (props.modalType === ModalType.EDIT && latestVersion?.businessClientLink !== form.latestVersion.businessClientLink) {
            if (!form.latestVersion.businessClientLinkUpdateSummary) {
                setSaving(false);
                return false;
            }    
        }

        if (props.modalType === ModalType.EDIT && latestVersion?.privateClientLink !== form.latestVersion.privateClientLink) {
            if (!form.latestVersion.privateClientLinkUpdateSummary) {
                setSaving(false);
                return false;
            }   
        }
        
        if (!form.confirmationText || !form.latestVersion.agreementWithdrawalConditions || !form.latestVersion.businessClientLink || !form.latestVersion.privateClientLink) {
            setSaving(false);
            return false;
        }

        return true;
    };

    const validateSubmit = () => {
        setValidated(false);
        const isValidForm = validateForm();
        setValidated(true);
        if (isValidForm) {
            setIsConfirmModalVisible(true);
        } else {
            setSaving(false);
        }
    };

    const handleSubmit = () => {
        setSaving(true);
        setSavingError(false);
        if (props.modalType === ModalType.CREATE) {
            TermsAndConditionsService.postTermsAndConditions(form).then(() => {
                setSaving(false);
                setTimeout(() => {
                    props.handleModalHide();
                }, 300);
                props.loadTermsAndConditions();
            }).catch((reason: any) => {
                console.error('Saving failed', reason);
                setSaving(false);
                setSavingError(true);
            });
        } else {
            if (!props.editingTermsAndConditions || !props.editingTermsAndConditions.id) {
                setSaving(false);
                console.error('Saving failed, no editable terms and conditions entry', props.editingTermsAndConditions);
                return;
            }

            TermsAndConditionsService.putTermsAndConditions(props.editingTermsAndConditions.id, form).then(() => {
                setSaving(false);
                setTimeout(() => {
                    props.handleModalHide();
                }, 300);
                props.loadTermsAndConditions();
            }).catch((reason: any) => {
                console.error('Saving failed', reason);
                setSaving(false);
                setSavingError(true);
            });
        }
    };

    return (
        <Modal
            show={true}
            size={"lg"}
            onHide={props.handleModalHide}
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    {
                        props.modalType === ModalType.CREATE &&
                        <span>Tüüptingimuse lisamine</span>
                    }
                    {
                        props.modalType === ModalType.EDIT &&
                        <span>Tüüptingimuse muutmine</span>
                    }
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={(e: any) => e.preventDefault()}
                      id={"termsAndConditions-form"}
                      className={'mt-1 align-self-center m-0'}>
                    <Form.Group controlId={'form-termsAndConditions-name'} className="row m-0">
                        <Form.Label className={'mb-0 align-self-center  text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Tüüptingimuse nimetus</span>
                        </Form.Label>
                        <Form.Control required className="col-12 col-lg-8"
                            minLength={1}
                            value={form.name}
                            onChange={(e) => setForm({
                                ...form,
                                name: e.target.value
                            })
                            } 
                            disabled={saving}
                            type="text"/>
                        <Form.Control.Feedback className={'col-12 '} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                    </Form.Group>

                                
                    <Form.Group controlId={'form-termsAndConditions-description'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center  text-start col-12 col-lg-3'}>
                            <span className="text-break">Tüüptingimusega nõustumise kirjeldus </span>
                        </Form.Label>
                        <Form.Control required className="col-12 col-lg-8"
                            value={form.confirmationText}
                            onChange={(e) => setForm({
                                ...form,
                                confirmationText: e.target.value
                            })
                            }
                            disabled={saving}
                            as="textarea"
                            rows={5}/>
                        <Form.Control.Feedback className={'col-12 '} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId={'form-termsAndConditions-description'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center  text-start col-12 col-lg-3'}>
                            <span className="text-break">Energiapekti tüüptingimusega nõustumise kirjeldus </span>
                        </Form.Label>
                        <Form.Control required className="col-12 col-lg-8"
                            value={form.loyaltyConfirmationText}
                            onChange={(e) => setForm({
                                ...form,
                                loyaltyConfirmationText: e.target.value
                            })
                            }
                            disabled={saving}
                            as="textarea"
                            rows={5}/>
                        <Form.Control.Feedback className={'col-12 '} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId={'form-termsAndConditions-business-client-link'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center  text-start col-12 col-lg-3'}>
                            <span className="text-break">Ärikliendi tüüptingimuste link</span>
                        </Form.Label>
                        <Form.Control required className="col-12 col-lg-8"
                            value={form.latestVersion.businessClientLink}
                            onChange={(e) => setForm({
                                ...form,
                                latestVersion: {
                                    ...form.latestVersion,
                                    businessClientLink: e.target.value
                                }                               
                            })
                            }
                            disabled={saving}
                            type="url"/>
                        <Form.Control.Feedback className={'col-12 '} type="invalid">Kohustuslik väli</Form.Control.Feedback>    
                    </Form.Group>

                    {props.modalType === ModalType.EDIT && latestVersion?.businessClientLink !== form.latestVersion.businessClientLink ?
                        <Form.Group controlId={'form-termsAndConditions-business-update-summary'} className="row m-0 mt-3">
                            <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                                <span className='text-break'>Ärikliendi tüüptingimuste uuendamise kokkuvõte</span>
                            </Form.Label>
                            <Form.Control required className="col-12 col-lg-8"
                                value={form.latestVersion.businessClientLinkUpdateSummary} 
                                onChange={(e) => setForm({
                                    ...form,
                                    latestVersion: {
                                        ...form.latestVersion,
                                        businessClientLinkUpdateSummary: e.target.value
                                    }
                                })}
                                disabled={saving}
                                as="textarea"
                                rows={5}/>
                            <Form.Control.Feedback className={'col-12 '} type="invalid">Kohustuslik väli</Form.Control.Feedback>            
                        </Form.Group> 
                    : null}   

                    <Form.Group controlId={'form-termsAndConditions-private-client-link'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center  text-start col-12 col-lg-3'}>
                            <span className="text-break">Erakliendi tüüptingimuste link</span>
                        </Form.Label>
                        <Form.Control required className="col-12 col-lg-8"
                            value={form.latestVersion.privateClientLink}
                            onChange={(e) => setForm({
                                ...form,
                                latestVersion: {
                                    ...form.latestVersion,
                                    privateClientLink: e.target.value
                                }
                            })
                            }
                            disabled={saving}
                            type="url"/>
                        <Form.Control.Feedback className={'col-12 '} type="invalid">Kohustuslik väli</Form.Control.Feedback>    
                    </Form.Group>
                    
                            
                    {props.modalType === ModalType.EDIT && latestVersion?.privateClientLink !== form.latestVersion.privateClientLink ?
                        <Form.Group controlId={'form-termsAndConditions-private-update-summary'} className="row m-0 mt-3">
                            <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                                <span className='text-break'>Erakliendi tüüptingimuste uuendamise kokkuvõte</span>
                            </Form.Label>
                            <Form.Control required className="col-12 col-lg-8"
                                value={form.latestVersion.privateClientLinkUpdateSummary} 
                                onChange={(e) => setForm({
                                    ...form,
                                    latestVersion: {
                                        ...form.latestVersion,
                                        privateClientLinkUpdateSummary: e.target.value
                                    }
                                })}
                                disabled={saving}
                                as="textarea"
                                rows={5}/>
                            <Form.Control.Feedback className={'col-12 '} type="invalid">Kohustuslik väli</Form.Control.Feedback>            
                        </Form.Group> 
                    : null}           
       

                    <Form.Group controlId={'form-termsAndConditions-contract-termination-conditon'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center  text-start col-12 col-lg-3'}>
                            <span className="text-break">Leppingu lõpetamise tingimused</span>
                        </Form.Label>
                        <Form.Control required className="col-12 col-lg-8"
                            value={form.latestVersion.agreementWithdrawalConditions}
                            onChange={(e) => setForm({
                                ...form,
                                latestVersion: {
                                    ...form.latestVersion,
                                    agreementWithdrawalConditions: e.target.value
                                }
                            })
                            }
                            disabled={saving}
                            as="textarea"
                            rows={5}/>
                        <Form.Control.Feedback className={'col-12 '} type="invalid">Kohustuslik väli</Form.Control.Feedback>     
                    </Form.Group>

                    <Form.Group controlId={'form-termsAndConditions-version'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center  text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Tüüptingimuste versioon</span>
                        </Form.Label>
                        {form.latestVersion.version !== undefined &&
                            <span className='font-weight-bold'>{form.latestVersion.version}</span>
                        }
                        {form.latestVersion.version === undefined &&
                            <span className='font-weight-bold'>1</span>
                        }
                     </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer className="d-flex flex-row align-items-end justify-content-around flex-wrap mb-1">
                <Button variant="info"
                        onClick={() => props.handleModalHide()}
                        type="button"
                        className={"d-flex align-items-center"}>
                    <span className='px-2'>Tühista</span>
                </Button>
                <Button variant="primary" onClick={validateSubmit} type="button" disabled={saving}
                        className="d-flex align-items-center">
                    {
                        !saving &&
                        <span className='px-2'>Salvesta</span>
                    }
                    {
                        saving &&
                        <>
                            <span className='px-2'>Salvestab…</span>
                            <Spinner size={"sm"} animation={"border"}/>
                        </>
                    }
                </Button>

            </Modal.Footer>
            {
                !saving && savingError &&
                <span className="d-flex justify-content-end text-danger px-2 pb-2">
                    Viga salvestamisel, proovi mõne hetke pärast uuesti.
                </span>
            }

            {
                validated && isConfirmModalVisible &&
                    <EditTermsAndConditionsConfirmModal
                        termsAndConditionsName={form.name}
                        handleConfirmSubmit={() => {
                            setIsConfirmModalVisible(false);
                            handleSubmit()
                        }}
                        handleConfirmReject={() => {
                            setSaving(false);
                            setIsConfirmModalVisible(false);
                        }}
                        modalType={props.modalType!}/>
            }
        </Modal>
    );
}
