import React, {PureComponent} from 'react';
import {Button, Spinner} from "react-bootstrap";
import {ModalType} from "../../common/common-models";
import {EnergyPackagesSearchBarProps} from "./models";

export class EnergyPackagesSearchBar extends PureComponent<EnergyPackagesSearchBarProps, any> {    
    render() {
        return (
            <div className="bar-position">
                <h2 className="text-light">
                    Energiapaketid
                </h2>
                <div className="card mb-5 shadow">
                    <div className="card-body">
                        <div className="d-flex flex-wrap flex-sm-nowrap">
                            <Button className="ml-auto d-flex align-items-center"
                                    onClick={() => this.props.openEnergyPackageModal(ModalType.CREATE)}
                                    variant={"primary"}>
                                <i className="icon--16 icon__add d-flex mr-1"/>
                                <span>Lisa uus</span>
                            </Button>
                            <Button 
                                className="ml-2 d-flex align-items-center"
                                onClick={() => this.props.syncGroups()}
                                variant="primary"
                            >
                                { this.props.isSyncing ? <Spinner size="sm" animation="border" className="mr-2" /> : <i className='icon__refresh-white text-white mr-2 ' />}
                                Uuenda gruppe
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
