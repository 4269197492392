import React from "react";
import {dateFormatOptions} from "../../common/dateFormatterConfig";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {ModalType} from "../../common/common-models";
import Pagination from "rc-pagination";
import {locale} from "../../common/table/paginatorConfig";
import {EnergyPackage, EnergyPackageTableProps} from "./models";

export function EnergyPackagesTable(props: EnergyPackageTableProps) {
    const energyPackages: Array<EnergyPackage> = props.energyPackages.content;
    const pageResponse = props.energyPackages;

    const electricityPackageTableRows = energyPackages.map((energyPackage: EnergyPackage) =>
        <tr key={'row-' + energyPackage.id}>
            <td>
                {energyPackage.id}
            </td>
            <td>
                {energyPackage.nameEt}
            </td>
            <td>

            </td>
            <td>
                {energyPackage.dateModified ? new Intl.DateTimeFormat('et', dateFormatOptions).format(new Date(energyPackage.dateModified)) : ""}
            </td>
            <td>
                <div className="d-flex justify-content-center">
                    <OverlayTrigger delay={250}
                                    overlay={
                                        <Tooltip id={`tooltip-${energyPackage.id}`}>
                                            <p style={{margin: '3px'}}>Muuda</p>
                                        </Tooltip>} placement={"left"}>
                        <Button size="sm" variant="outline-dark"
                                className="d-flex align-items-center border-color-background border-radius-circle btn-outline-icon"
                                onClick={() => {
                                    props.openEnergyPackageModal(ModalType.EDIT, energyPackage);
                                }}>
                            <i className="icon--16 my-2 icon__edit--outline-mask"/>
                        </Button>
                    </OverlayTrigger>
                </div>
            </td>
        </tr>
    );

    return (
        <table className='generic-table table table-responsive-md table-hover'>
            <thead>
            <tr>
                <th>Id</th>
                <th>Nimi</th>
                <th></th>
                <th>Viimati muudetud</th>
                <th>Tegevused</th>
            </tr>
            </thead>
            <tbody>
            {electricityPackageTableRows}
            </tbody>
            <tfoot>
            <tr>
                <td colSpan={5}>
                    <div className="pagination-wrapper">
                        <Pagination
                            locale={locale}
                            current={pageResponse.number + 1}
                            total={pageResponse.totalElements}
                            pageSize={pageResponse.size}
                            prefixCls={"pagination"}
                            showSizeChanger={false}
                            hideOnSinglePage={true}
                        />
                    </div>
                </td>
            </tr>
            </tfoot>
        </table>
    )
}
