import axios from "axios";
import {apiService} from "../api.service";
import {extractData, handleResponse} from "../../helpers/apiResponseHelper";


async function getAllEnergyGroups() {
    return axios.get(apiService.getApiUrl() + '/energy-groups/admin').then(handleResponse).then(extractData)
}

async function syncEnergyGroups() {
    return axios.get(apiService.getApiUrl() + '/energy-groups/admin/sync').then(handleResponse)
}

const EnergyGroupService = {
    getAllEnergyGroups,
    syncEnergyGroups
}

export default EnergyGroupService;
