import React, {useState} from 'react';
import {Button, Form, InputGroup, Modal, Spinner} from "react-bootstrap";
import {ModalType} from "../../../common/common-models";
import LoyaltyPackagesService from "../energy-packages.service";
import {isNotNullOrUndefined} from "../../../common/data-util";
import {EnergyPackageForm, EnergyPackageModalProps} from "../models";
import Select from "react-select";
import { EditEnergyPackageConfirmModal } from './EditEnergyPackageConfirmModal';

export function EditEnergyPackageModal(props: EnergyPackageModalProps) {
    const [saving, setSaving]: [boolean, any] = useState(false);
    const [savingError, setSavingError]: [boolean, any] = useState(false);
    const [validated, setValidated]: [boolean, any] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible]: [boolean, any] = useState(false);
    const [form, setForm]: [EnergyPackageForm, any] = useState({
        nameEt: props.editingEnergyPackage?.nameEt ?? "",
        nameEn: props.editingEnergyPackage?.nameEn ?? "",
        nameRu: props.editingEnergyPackage?.nameRu ?? "",
        monthlyFeeEuros: props.editingEnergyPackage?.monthlyFeeEuros,
        freeElectricityContractsCount: props.editingEnergyPackage?.freeElectricityContractsCount,
        monthlyVolumeLimit: props.editingEnergyPackage?.monthlyVolumeLimit,
        priceCeiling: props.editingEnergyPackage?.priceCeiling ?? false,
        publicTariffDiscount: props.editingEnergyPackage?.publicTariffDiscount,
        timeBasedTariffDiscount: props.editingEnergyPackage?.timeBasedTariffDiscount,
        homeChargerDiscount: props.editingEnergyPackage?.homeChargerDiscount,
        homeChargerDiscountCode: props.editingEnergyPackage?.homeChargerDiscountCode,
        smartCharging: props.editingEnergyPackage?.smartCharging ?? false,
        orderNumber: props.editingEnergyPackage?.orderNumber ?? 0,
        primaryGroup: props.editingEnergyPackage?.primaryGroup,
        fallbackGroup: props.editingEnergyPackage?.fallbackGroup,
        isDefault: props.editingEnergyPackage?.isDefault ?? false,
        highlighted: props.editingEnergyPackage?.highlighted ?? false,
    });

    const validateForm = () => {
        if (!form.nameEt || !form.nameEt.trim().length) return false;
        if (!isNotNullOrUndefined(form.orderNumber)) return false;
        if (!isNotNullOrUndefined(form.primaryGroup)) return false;
        return true;
    };

    const validateSubmit = () => {
        setValidated(false);
        const isValidForm = validateForm();
        setValidated(true);
        if (isValidForm) {
            setIsConfirmModalVisible(true);
        } else {
            setSaving(false);
        }
    };

    const handleSubmit = () => {
        setSaving(true);
        setSavingError(false);
        if (props.modalType === ModalType.CREATE) {
            LoyaltyPackagesService.postEnergyPackage(form).then(() => {
                setSaving(false);
                setTimeout(() => {
                    props.handleModalHide();
                }, 300);
                props.loadEnergyPackages();
            }).catch((reason: any) => {
                console.error('Saving failed', reason);
                setSaving(false);
                setSavingError(true);
            });
        } else {
            if (!props.editingEnergyPackage || !props.editingEnergyPackage.id) {
                setSaving(false);
                console.error('Saving failed, no editable loyalty package', props.editingEnergyPackage);
                return;
            }

            LoyaltyPackagesService.patchEnergyPackage(props.editingEnergyPackage.id, form).then(() => {
                setSaving(false);
                setTimeout(() => {
                    props.handleModalHide();
                }, 300);
                props.loadEnergyPackages();
            }).catch((reason: any) => {
                console.error('Saving failed', reason);
                setSaving(false);
                setSavingError(true);
            });
        }
    };

    const selectOptions = props.energyGroups?.map(group => {
        return { label: group.name, value: group.name }
    });

    return (
        <Modal
            show={true}
            size={"xl"}
            onHide={props.handleModalHide}
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    {
                        props.modalType === ModalType.CREATE &&
                        <span>Energiapaketi lisamine</span>
                    }
                    {
                        props.modalType === ModalType.EDIT &&
                        <span>Energiapaketi muutmine</span>
                    }
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={(e: any) => e.preventDefault()}
                      id={"electricity-package-form"}
                      className={'mt-1 align-self-center m-0'}>
                    <Form.Group controlId={'form-loyalty-package-name'} className="row m-0">
                        <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Energiapaketi eestikeelne nimetus*</span>
                        </Form.Label>
                        <Form.Control
                            className="col-12 col-lg-8"
                            minLength={1}
                            value={form.nameEt}
                            onChange={(e) => setForm({
                                ...form,
                                nameEt: e.target.value
                            })
                            }
                            disabled={saving}
                            type="text"
                            required
                        />
                        <Form.Control.Feedback className={'col-12 col-lg-8 offset-lg-3 pl-0'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId={'form-loyalty-package-name-in-english'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Energiapaketi inglisekeelne nimetus</span>
                        </Form.Label>
                        <Form.Control
                            className="col-12 col-lg-8"
                            value={form.nameEn}
                            onChange={(e) => setForm({
                                ...form,
                                nameEn: e.target.value
                            })
                            }
                            disabled={saving}
                            type="text"
                        />
                    </Form.Group>
                    <Form.Group controlId={'form-loyalty-package-name-in-russian'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Energiapaketi venekeelne nimetus</span>
                        </Form.Label>
                        <Form.Control
                            className="col-12 col-lg-8"
                            value={form.nameRu}
                            onChange={(e) => setForm({
                                ...form,
                                nameRu: e.target.value
                            })
                            }
                            disabled={saving}
                            type="text"
                        />
                    </Form.Group>
                    <Form.Group controlId={'form-loyalty-package-order-number'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Energiapaketi järjekorra number *</span>
                        </Form.Label>
                        <Form.Control
                            className="col-12 col-lg-8"
                            min={0}
                            value={form.orderNumber}
                            onChange={(e) => setForm({
                                ...form,
                                orderNumber: e.target.value
                            })
                            }
                            disabled={saving}
                            type="number"
                            required
                        />
                        <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId={'form-loyalty-package-free-electricity-contracts-count'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Kuutasuta elektrilepingute arv</span>
                        </Form.Label>
                        <Form.Control
                            className="col-12 col-lg-8"
                            value={form.freeElectricityContractsCount}
                            onChange={(e) => setForm({
                                ...form,
                                freeElectricityContractsCount: e.target.value
                            })
                            }
                            disabled={saving}
                            type="number"
                        />
                    </Form.Group>
                    <Form.Group controlId={'form-loyalty-package-monthly-volume-limit'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Kuutasu (€)</span>
                        </Form.Label>
                        <Form.Control
                            className="col-12 col-lg-8"
                            value={form.monthlyFeeEuros}
                            onChange={(e) => setForm({
                                ...form,
                                monthlyFeeEuros: e.target.value
                            })
                            }
                            step={0.5}
                            disabled={saving}
                            type="number"
                        />
                    </Form.Group>
                    <Form.Group controlId={'form-loyalty-package-monthly-volume-limit'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Kuumahu piirang</span>
                        </Form.Label>
                        <Form.Control
                            className="col-12 col-lg-8"
                            step={0.5}
                            value={form.monthlyVolumeLimit}
                            onChange={(e) => setForm({
                                ...form,
                                monthlyVolumeLimit: e.target.value
                            })
                            }
                            disabled={saving}
                            type="number"
                        />
                        <Form.Control.Feedback className={'col-12 col-lg-8 offset-lg-3 pl-0'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId={'form-loyalty-package-home-charger-discount'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Kodulaadija soodustus</span>
                        </Form.Label>
                        <Form.Control
                            className="col-12 col-lg-8"
                            step={0.5}
                            value={form.homeChargerDiscount}
                            onChange={(e) => setForm({
                                ...form,
                                homeChargerDiscount: e.target.value
                            })
                            }
                            disabled={saving}
                            type="number"
                        />
                    </Form.Group>
                    <Form.Group controlId={'form-loyalty-package-home-charger-discount-code'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Kodulaadija sooduskood</span>
                        </Form.Label>
                        <Form.Control
                            className="col-12 col-lg-8"
                            value={form.homeChargerDiscountCode}
                            onChange={(e) => setForm({
                                ...form,
                                homeChargerDiscountCode: e.target.value
                            })
                            }
                            disabled={saving}
                            type="text"
                        />
                    </Form.Group>
                    <Form.Group controlId={'form-energy-package-smart-charging'} className="row m-0 mt-3">
                        <div className='col-12 col-lg-3' />
                        <Form.Check
                            id='check-smart-charging'
                            custom
                            type="checkbox"
                            className='checkbox-large'
                            label='Tark laadimine'
                            onChange={() => setForm({...form, smartCharging: !form.smartCharging})}
                            checked={form.smartCharging}
                        />
                        <Form.Control.Feedback className={'col-12 col-lg-8 offset-lg-3 pl-0'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId={'form-energy-package-price-ceiling'} className="row m-0 mt-1">
                        <div className='col-12 col-lg-3' />
                        <Form.Check
                            id='check-price-ceiling'
                            custom
                            type="checkbox"
                            className='checkbox-large'
                            label='Hinnalagi'
                            onChange={() => setForm({...form, priceCeiling: !form.priceCeiling})}
                            checked={form.priceCeiling}
                        />
                        <Form.Control.Feedback className={'col-12 col-lg-8 offset-lg-3 pl-0'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId={'form-loyalty-package-public-tariff-discount'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Avalik laadimine (s/kWh)</span>
                        </Form.Label>
                        <Form.Control
                            className="col-12 col-lg-8"
                            step={0.5}
                            value={form.publicTariffDiscount}
                            onChange={(e) => setForm({
                                ...form,
                                publicTariffDiscount: e.target.value
                            })
                            }
                            disabled={saving}
                            type="number"
                        />
                    </Form.Group>
                    <Form.Group controlId={'form-energy-package-highlighted'} className="row m-0 mt-3">
                        <div className='col-12 col-lg-3' />
                        <Form.Check
                            id='check-highlighted'
                            custom
                            type="checkbox"
                            className='checkbox-large'
                            label="Esile tõstetud"
                            onChange={() => setForm({...form, highlighted: !form.highlighted})}
                            checked={form.highlighted}
                        />
                    </Form.Group>
                    <Form.Group controlId={'form-energy-package-is-default'} className="row m-0 mt-1">
                        <div className='col-12 col-lg-3' />
                        <Form.Check
                            id='check-is-default'
                            custom
                            type="checkbox"
                            className='checkbox-large'
                            label='Stardipakett'
                            onChange={() => setForm({...form, isDefault: !form.isDefault})}
                            checked={form.isDefault}
                        />
                    </Form.Group>
                    <Form.Group className="col-12 p-0 mt-3">
                        <div className="col-12 d-flex flex-row">
                        <Form.Label className="col-6 px-0 px-lg-3 ml-lg-4 d-flex justify-content-lg-around  flex-column flex-lg-row">
                            <span className="text-nowrap">Primaarne grupp*</span>
                        </Form.Label>
                        <Form.Label className="col-6 px-0 px-lg-3 ml-lg-4 d-md-flex d-none justify-content-lg-around flex-column flex-lg-row">
                            <span className="text-nowrap">Taanduv grupp</span>
                        </Form.Label>
                        </div>
                        <InputGroup className="col-12 px-0 px-lg-3 ml-lg-4 d-flex justify-content-lg-around flex-column flex-lg-row">
                            <Select
                                options={selectOptions?.filter((option) => option.value !== form.primaryGroup?.name && option.value !== form.fallbackGroup?.name)}
                                value={selectOptions?.find((option) => option.value === form.primaryGroup?.name) ?? { value: form.primaryGroup?.name, label: form.primaryGroup?.name}}
                                onChange={(value: any) => setForm({
                                    ...form,
                                    primaryGroup: props.energyGroups?.find((energyGroup) => energyGroup.name === value.value)
                                })}
                                isDisabled={saving}
                                noOptionsMessage={() => "Ei leidnud gruppe"}
                                className={'col-lg-4 mt-2 mt-lg-0 form-select' + (!form.primaryGroup ? ' is-invalid' : '')}
                                placeholder='Vali primaarne grupp'
                            />
                            <Form.Label className="col-6 px-0 px-lg-3 mt-2 ml-lg-4 d-md-none d-flex justify-content-lg-around flex-column flex-lg-row">
                                <span className="text-nowrap">Taanduv grupp*</span>
                            </Form.Label>
                            <Select
                                options={selectOptions?.filter((option) => option.value !== form.primaryGroup?.name && option.value !== form.fallbackGroup?.name)}
                                value={selectOptions?.find((option) => option.value === form.fallbackGroup?.name)  ?? { value: form.fallbackGroup?.name, label: form.fallbackGroup?.name}}
                                onChange={(value: any) => setForm({
                                    ...form,
                                    fallbackGroup: props.energyGroups?.find((energyGroup) => energyGroup.name === value.value)
                                })}
                                noOptionsMessage={() => "Ei leidnud rohkem gruppe"}
                                isDisabled={saving}
                                className='col-lg-4 form-select'
                                placeholder='Vali taanduv grupp'
                            />
                        </InputGroup>
                        <InputGroup className="col-12 px-0 px-lg-3 mt-3 ml-lg-4 d-flex justify-content-lg-around flex-column flex-lg-row">
                            <div className="d-flex p-0 m-0 flex-column col-lg-4 w-100">
                                <Form.Label className={'mb-0 d-block text-start'}>Kütuse ja laadimise soodukas (s/L ja s/kWh)</Form.Label>
                                <Form.Control
                                    placeholder="Primaarse grupi kütuse ja laadimise soodukas"
                                    value={form.primaryGroup?.fuelDiscount ?? 0}
                                    onChange={(e) => setForm({
                                        ...form,
                                        primaryGroup: {
                                            ...form.primaryGroup,
                                            chargingDiscount: e.target.value,
                                            fuelDiscount: e.target.value
                                        }
                                    })
                                    }
                                    min={0}
                                    disabled={saving}
                                    type="number"
                                />
                            </div>
                            <div className="d-flex p-0 m-0 flex-column col-lg-4 w-100">
                                <Form.Label className={'mb-0 d-block text-start'}>Kütuse ja laadimise soodukas (s/L ja s/kWh)</Form.Label>
                                <Form.Control
                                    placeholder="Taanduva grupi kütuse ja laadimise soodukas"
                                    value={form.fallbackGroup?.fuelDiscount}
                                    onChange={(e) => setForm({
                                        ...form,
                                        fallbackGroup: {
                                            ...form.fallbackGroup,
                                            chargingDiscount: e.target.value,
                                            fuelDiscount: e.target.value
                                        }
                                    })
                                    }
                                    min={0}
                                    disabled={saving}
                                    type="number"/>
                            </div>
                        </InputGroup>
                        <InputGroup className="col-12 px-0 px-lg-3 mt-3 ml-lg-4 d-flex justify-content-lg-around flex-column flex-lg-row">
                            <div className="d-flex p-0 m-0 flex-column col-lg-4 w-100">
                            <Form.Label className={'mb-0 d-block text-start'}>Autopesu soodukas (%)</Form.Label>
                            <Form.Control
                                placeholder="Primaarse grupi autopesu soodukas"
                                value={form.primaryGroup?.carWashDiscount ?? ''}
                                onChange={(e) => {
                                    setForm({
                                        ...form,
                                        primaryGroup: {
                                            ...form.primaryGroup,
                                            carWashDiscount: e.target.value
                                        }
                                    })
                                }
                                }
                                min={0}
                                disabled={saving}
                                type="number"/>
                            </div>
                            <div className="d-flex p-0 m-0 flex-column col-lg-4 w-100">
                                <Form.Label className={'mb-0 d-block text-start'}>Autopesu soodukas (%)</Form.Label>
                            <Form.Control
                                placeholder="Taanduva grupi autopesu soodukas"
                                value={form.fallbackGroup?.carWashDiscount}
                                onChange={(e) => setForm({
                                    ...form,
                                    fallbackGroup: {
                                        ...form.fallbackGroup,
                                        carWashDiscount: e.target.value
                                    }
                                })
                                }
                                min={0}
                                disabled={saving}
                                type="number"/>
                            </div>
                        </InputGroup>
                        <InputGroup className="col-12 px-0 px-lg-3 mt-3 ml-lg-4 d-flex justify-content-lg-around flex-column flex-lg-row">
                            <div className="d-flex p-0 m-0 flex-column col-lg-4 w-100">
                                <Form.Label className={'mb-0 d-block text-start'}>Autokäru soodukas (%)</Form.Label>
                            <Form.Control
                                placeholder="Primaarse grupi autokäru soodukas"
                                value={form.primaryGroup?.trailerDiscount ?? 0}
                                onChange={(e) => setForm({
                                        ...form,
                                    primaryGroup: {
                                        ...form.primaryGroup,
                                        trailerDiscount: e.target.value
                                        }
                                    })
                                }
                                min={0}
                                disabled={saving}
                                type="number"/>
                            </div>
                            <div className="d-flex p-0 m-0 flex-column col-lg-4 w-100">
                                <Form.Label className={'mb-0 d-block text-start'}>Autokäru soodukas (%)</Form.Label>
                            <Form.Control
                                placeholder="Taanduva grupi autokäru soodukas"
                                value={form.fallbackGroup?.trailerDiscount}
                                onChange={(e) => setForm({
                                    ...form,
                                    fallbackGroup: {
                                        ...form.fallbackGroup,
                                        trailerDiscount: e.target.value
                                    }
                                })
                                }
                                min={0}
                                disabled={saving}
                                type="number"/>
                            </div>
                        </InputGroup>
                        <InputGroup className="col-12 px-0 px-lg-3 mt-3 ml-lg-4 d-flex justify-content-lg-around flex-column flex-lg-row">
                            <div className="d-flex p-0 m-0 flex-column col-lg-4 w-100">
                                <Form.Label className={'mb-0 d-block text-start'}>Balloonigaasi soodukas (%)</Form.Label>
                            <Form.Control
                                placeholder="Primaarse grupi balloonigaasi soodukas"
                                value={form.primaryGroup?.cylinderGasDiscount ?? 0}
                                onChange={(e) => setForm({
                                    ...form,
                                    primaryGroup: {
                                        ...form.primaryGroup,
                                        cylinderGasDiscount: e.target.value
                                    }
                                })
                                }
                                disabled={saving}
                                type="number"/>
                            </div>
                            <div className="d-flex p-0 m-0 flex-column col-lg-4 w-100">
                                <Form.Label className={'mb-0 d-block text-start'}>Balloonigaasi soodukas (%)</Form.Label>
                            <Form.Control
                                placeholder="Taanduva grupi balloonigaasi soodukas"
                                value={form.fallbackGroup?.cylinderGasDiscount}
                                onChange={(e) => setForm({
                                    ...form,
                                    fallbackGroup: {
                                        ...form.fallbackGroup,
                                        cylinderGasDiscount: e.target.value
                                    }
                                })
                                }
                                min={0}
                                disabled={saving}
                                type="number"/>
                            </div>
                        </InputGroup>
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer className="d-flex flex-row align-items-end justify-content-around flex-wrap mb-1">
                <Button variant="info"
                        onClick={() => props.handleModalHide()}
                        type="button"
                        className={"d-flex align-items-center"}>
                    <span className='px-2'>Tühista</span>
                </Button>
                <Button variant="primary" onClick={validateSubmit} type="button" disabled={saving}
                        className="d-flex align-items-center">
                    {
                        !saving &&
                        <span className='px-2'>Salvesta</span>
                    }
                    {
                        saving &&
                        <>
                            <span className='px-2'>Salvestab…</span>
                            <Spinner size={"sm"} animation={"border"}/>
                        </>
                    }
                </Button>

            </Modal.Footer>
            {
                !saving && savingError &&
                <span className="d-flex justify-content-end text-danger px-2 pb-2">
                    Viga salvestamisel, proovi mõne hetke pärast uuesti.
                </span>
            }
            {
                validated && isConfirmModalVisible &&
                <EditEnergyPackageConfirmModal
                    energyPackageName={form.nameEt}
                    handleConfirmSubmit={() => {
                        setIsConfirmModalVisible(false);
                        handleSubmit()
                    }}
                    handleConfirmReject={() => {
                        setSaving(false);
                        setIsConfirmModalVisible(false);
                    }}
                    modalType={props.modalType}/>
            }
        </Modal>
    );
}
