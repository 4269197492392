import React from "react";
import {dateFormatOptions} from "../../common/dateFormatterConfig";
import Pagination from "rc-pagination";
import {locale} from "../../common/table/paginatorConfig";
import { LOYALTY_CAMPAIGN_CALCULATION_TYPE_MAP, LOYALTY_CAMPAIGN_TYPE_MAP, LoyaltyCampaign, LoyaltyCampaignSortFields, LoyaltyCampaignsTableProps } from "./models";
import { SortIcons } from "components/SortIcons/SortIcons";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

export function LoyaltyCampaignsTable(props: LoyaltyCampaignsTableProps) {
    const loyaltyCampaigns: Array<LoyaltyCampaign> = props.campaigns.content;
    const pageResponse = props.campaigns;

    const loyaltyCampaignTableRows = loyaltyCampaigns.map((loyaltyCampaign: LoyaltyCampaign) =>
        <tr key={'row-' + loyaltyCampaign.id}>
            <td>
                {loyaltyCampaign.name}
            </td>
            <td>
                {loyaltyCampaign.type ? LOYALTY_CAMPAIGN_TYPE_MAP[loyaltyCampaign.type] : ""}
            </td>
            <td>
                {loyaltyCampaign.calculationType ? LOYALTY_CAMPAIGN_CALCULATION_TYPE_MAP[loyaltyCampaign.calculationType] : ""}
            </td>
            <td>
                {loyaltyCampaign.startDate ? new Intl.DateTimeFormat('et', dateFormatOptions).format(new Date(loyaltyCampaign.startDate)) : ""}
            </td>
            <td>
                {loyaltyCampaign.endDate ? new Intl.DateTimeFormat('et', dateFormatOptions).format(new Date(loyaltyCampaign.endDate)) : ""}
            </td>  
            <td>
                {loyaltyCampaign.targetPoints}
            </td>
            <td>
                {loyaltyCampaign.orderNumber}
            </td>
            <td>
                {loyaltyCampaign.active ? "Aktiivne" : "Mitteaktiivne"}
            </td>   
            <td>
                <div className="row__actions">
                    <OverlayTrigger
                            delay={250}
                            overlay={
                                <Tooltip id={`update-package-tooltip-${loyaltyCampaign.id}`}>
                                    <p style={{margin: '3px'}}>Muuda kampaaniat</p>
                                </Tooltip>
                            } 
                            placement={"left"}
                    >
                        <Button 
                            size="sm"                             
                            variant="secondary"
                            className="d-flex align-items-center btn-outline-icon mr-2" 
                            onClick={() => props.updateLoyaltyCampaign(loyaltyCampaign)}     
                        >
                                <i className="icon--16 my-2 icon__edit--outline-mask"/>
                        </Button>
                    </OverlayTrigger>
                </div>
            </td>
        </tr>
    );

    return (
        <table className='generic-table table table-responsive-md table-hover'>
            <thead>
            <tr>
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(LoyaltyCampaignSortFields.NAME)}>
                        <span>Nimi</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={LoyaltyCampaignSortFields.NAME} />
                    </div>
                </th> 
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(LoyaltyCampaignSortFields.TYPE)}>
                        <span>Kampaania tüüp</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={LoyaltyCampaignSortFields.TYPE} />
                    </div>
                </th> 
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(LoyaltyCampaignSortFields.CALCULATION_TYPE)}>
                        <span>Kalkulatsiooni tüüp</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={LoyaltyCampaignSortFields.CALCULATION_TYPE} />
                    </div>
                </th> 
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(LoyaltyCampaignSortFields.START_DATE)}>
                        <span>Alguskuupäev</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={LoyaltyCampaignSortFields.START_DATE} />
                    </div>
                </th> 
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(LoyaltyCampaignSortFields.END_DATE)}>
                        <span>Lõppkuupäev</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={LoyaltyCampaignSortFields.END_DATE} />
                    </div>
                </th>  
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(LoyaltyCampaignSortFields.TARGET_POINTS)}>
                        <span>Punktid</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={LoyaltyCampaignSortFields.TARGET_POINTS} />
                    </div>
                </th> 
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(LoyaltyCampaignSortFields.ORDER_NUMBER)}>
                        <span>Järjekorra number</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={LoyaltyCampaignSortFields.ORDER_NUMBER} />
                    </div>
                </th> 
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(LoyaltyCampaignSortFields.ACTIVE)}>
                        <span>Staatus</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={LoyaltyCampaignSortFields.ACTIVE} />
                    </div>
                </th>    
            </tr>
            </thead>
            <tbody>
                {loyaltyCampaignTableRows}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={9}>
                        <div className="pagination-wrapper">
                            <Pagination
                                locale={locale}
                                current={pageResponse.number + 1}
                                total={pageResponse.totalElements}
                                pageSize={pageResponse.size}
                                prefixCls={"pagination"}
                                onChange={props.onPageChange}
                                showSizeChanger={false}
                                hideOnSinglePage={true}
                            />
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    )
}