/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import {LanguageOption, UpdateLoyaltyCampaignModalProps} from "common/common-models";
import React, {useState} from "react";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import {LoyaltyCampaignIcon, LoyaltyCampaignType, UpdateLoyaltyCampaignModalForm} from "../models";
import Select from 'react-select';
import {LoyaltyCampaignService} from "../LoyaltyCampaign.service";
import {toast} from "react-toastify";
import {getImageProperty, onNumberChange} from "helpers";
import {FileUploadFormGroup} from "components/FileUpload/FileUploadFormGroup";

const CAMPAIGN_TYPES = [
    { label: "Kohvi", value: LoyaltyCampaignType.COFFEE },
    { label: "Toit", value: LoyaltyCampaignType.FOOD },
    { label: "Raha", value: LoyaltyCampaignType.MONEY },
    { label: "Boonusraha", value: LoyaltyCampaignType.BONUS_MONEY },
    { label: "Kategooriata", value: LoyaltyCampaignType.UNCATEGORIZED }
]

const CAMPAIGN_ICON_OPTIONS = [
    LoyaltyCampaignIcon.STAR,
    LoyaltyCampaignIcon.BURGER,
    LoyaltyCampaignIcon.COFFEE,
    LoyaltyCampaignIcon.HOT_DOG
]

const ACCEPTED_MIME_TYPES = {
    'image/svg+xml': ['.svg'],
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/webp': ['.webp']
};

export function UpdateLoyaltyCampaignModal(props: UpdateLoyaltyCampaignModalProps) {
    const [form, setForm] = useState<UpdateLoyaltyCampaignModalForm>({
        validated: false,
        campaign: {
            id: props.campaign.id,
            type: props.campaign.type,
            active: props.campaign.active,
            titleEt: props.campaign.titleEt,
            titleEn: props.campaign.titleEn,
            participatable: props.campaign.participatable,
            orderNumber: props.campaign.orderNumber,
            voucherLabelEt: props.campaign.voucherLabelEt,
            voucherLabelEn: props.campaign.voucherLabelEn,
        },
        imageEt: {
            fileName: props.campaign.originalFileNameEt,
            uploaded: false
        },
        imageEn: {
            fileName: props.campaign.originalFileNameEn,
            uploaded: false
        },
        icon: props.campaign.icon,
    });

    const [saving, setSaving] = useState(false);

    const validateSubmit = () => {
        if (validate()) {
            submit();
        }
    }

    const validate = (): boolean => {
        setForm({ ...form, validated: true });

        if(!form.campaign.type){
            return false;
        }

        if(form.campaign.active && form.campaign.type !== LoyaltyCampaignType.BONUS_MONEY){
            if(!form.icon || !form.imageEt?.fileUrl){
                return false
            }
            if(!form.campaign.titleEt){
                return false;
            }
            return true;
        }

        return true;
    }

    const submit = async () => {
        setSaving(true);
        
        try{
            await uploadFile(LanguageOption.ET)
            await uploadFile(LanguageOption.EN)

            await LoyaltyCampaignService.updateLoyaltyCampaign(form);

            setSaving(false);

            props.onSuccess?.();
            props.handleModalHide();
        }catch(e){
            console.error(e);
        }finally{
            setSaving(false);
        }
    }

    const uploadFile = async (lang: LanguageOption) => {
        const file = form[getImageProperty(lang)]?.file;
        const isUploaded = form[getImageProperty(lang)]?.uploaded;
        
        if(!file || !isUploaded){
            return;
        }

        try{
            await LoyaltyCampaignService.uploadLoyaltyCampaignsImage(
                file,
                form.campaign.id,
                lang
            );
        }catch(e){
            let languageType = "Eestikeelse";
            if(lang === LanguageOption.EN){
                languageType = "Inglisekeelse" 
            }
            toast(`${languageType} faili üleslaadimine ebaõnnestus`, {
                position: "top-right",
                type: 'error'
            })
            return;
        }
    }

    const getImageFile = async (lang: LanguageOption) => {
        const res = await LoyaltyCampaignService.getLoyaltyCampaignsImage(props.campaign.id, lang);

        setForm((prevForm) => ({
            ...prevForm,
            [getImageProperty(lang)]: {
                ...prevForm[getImageProperty(lang)],
                fileUrl: res.data,
                uploaded: false
            }
        }))
    }

    const onImageDrop = (file: File, lang: LanguageOption) => {
        setForm({
            ...form,
            validated: false,
            [getImageProperty(lang)] : {
                file,
                fileUrl: window.URL.createObjectURL(file),
                fileName: file.name,
                uploaded: true
            }
          })
    }

    const onImageRemove = (lang: LanguageOption) => {
        setForm({
            ...form,
            [getImageProperty(lang)] : {
                fileName: undefined,
                fileUrl: undefined,
                file: undefined,
                uploaded: false
            }
        })
    }

    const currentCampaignType = CAMPAIGN_TYPES.find(cmp => cmp.value === form.campaign.type);
    const btnIsDisabled = saving;
    const uploadFormIsInvalid = !form?.imageEt?.file && form.campaign.active && form.campaign.type !== LoyaltyCampaignType.BONUS_MONEY && form.validated;

    return (
      <Modal
        size="lg"
        show={true}
        onHide={props.handleModalHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Muuda kampaaniat
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form 
                noValidate 
                validated={form.validated} 
                onSubmit={(e: any) => e.preventDefault()}
                id={"update-loyalty-campaign-form"}
                className={'w-full mt-1 align-self-center m-0'}
            >
                <Form.Group 
                    controlId={'form-loyalty-campaign-type'} 
                    className="row"
                >
                    <Form.Label 
                        className="col-3"
                    >
                        <span className="text-nowrap">Kampaania tüüp*</span>
                    </Form.Label>
                    <div className="col">
                        <Select
                            options={CAMPAIGN_TYPES}
                            value={currentCampaignType}
                            onChange={(val: any) => setForm({
                                ...form,
                                campaign: {
                                    ...form.campaign,
                                    type: val.value
                                }
                            })}
                            isDisabled={saving}
                            className={'form-control form-select' + (!form.campaign.type && form.validated ? ' is-invalid' : '')}
                            placeholder='Vali kampaania tüüp'
                            required
                        />
                        <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                        <Form.Group 
                            controlId={'form-loyalty-campaign-active'} 
                            className="mt-3"
                        >
                            <Form.Check
                                className='w-full form-check-input-no-label'
                                id='form-loyalty-campaign-active-check'
                                label='Aktiivne'
                                checked={form.campaign.active}
                                onChange={(e: any) => setForm({
                                    ...form,
                                    campaign: {
                                        ...form.campaign,
                                        active: e.target.checked
                                    }
                                })}
                                disabled={saving}
                                type='checkbox'
                            />
                        </Form.Group>
                        <Form.Group 
                            controlId={'form-loyalty-campaign-participatable'} 
                            className="mt-3"
                        >
                            <Form.Check
                                className='w-full form-check-input-no-label'
                                id='form-loyalty-campaign-participatable-check'
                                label='Osalemise võimalus'
                                checked={form.campaign.participatable}
                                onChange={(e: any) => setForm({
                                    ...form,
                                    campaign: {
                                        ...form.campaign,
                                        participatable: e.target.checked
                                    }
                                })}
                                disabled={saving}
                                type='checkbox'
                            />
                        </Form.Group>
                    </div>
                    <div className="col-5"/>
                </Form.Group> 
                <Form.Group className="row">
                    <Form.Label 
                        className="col-3"
                    >
                        <span className="text-nowrap d-flex flex-column">
                            <span>Lubatud grupid</span>
                        </span>
                    </Form.Label>
                    <div className="col-4">
                        {props.campaign?.allowedGroups ? (
                            props.campaign.allowedGroups.map((group, index) => (
                                <span key={index} className="mr-2 p-1 border">
                                    {group}
                                </span>
                            ))
                        ) : (
                            <span>Puudub</span>
                        )}
                    </div>
                </Form.Group>
                <Form.Group className="row">
                    <Form.Label className="col-3">
                        Järjekorra number
                    </Form.Label>
                    <div className="col-4">
                        <Form.Control
                            value={form.campaign.orderNumber}
                            onChange={(e: any) => setForm({
                                ...form,
                                campaign: {
                                    ...form.campaign,
                                    orderNumber: onNumberChange(e)
                                }
                            })}
                            type="text"
                        />
                    </div>
                </Form.Group>
                <Form.Group className="row">
                    <Form.Label 
                        className="col-3"
                    >
                        <span className="text-nowrap d-flex flex-column">
                            <span>Kampaania</span>
                            <span>eestikeelne tiitel*</span>
                        </span>
                    </Form.Label>
                    <div className="col-4">
                        <Form.Control
                            value={form.campaign.titleEt ?? ''}
                            onChange={(e: any) => setForm({
                                ...form, 
                                campaign: {
                                    ...form.campaign,
                                    titleEt: e.target.value
                                }
                            })}
                            minLength={4}
                            type="text"
                            required
                        />
                        <Form.Control.Feedback className={'text-right'} type="invalid">Eestikeelne tiitel on kohustuslik!</Form.Control.Feedback>
                    </div>
                </Form.Group>
                <Form.Group className="row">
                    <Form.Label 
                        className="col-3"
                    >
                        <span className="text-nowrap d-flex flex-column">
                            <span>Kampaania</span>
                            <span>inglisekeelne tiitel</span>
                        </span>
                    </Form.Label>
                    <div className="col-4">
                        <Form.Control
                            value={form.campaign.titleEn ?? ''}
                            onChange={(e: any) => setForm({
                                ...form, 
                                campaign: {
                                    ...form.campaign,
                                    titleEn: e.target.value
                                }
                            })}
                            minLength={4}
                            type="text"
                        />
                    </div>
                </Form.Group>
                <Form.Group className="row">
                    <Form.Label
                        className="col-3"
                    >
                        <span className="text-nowrap d-flex flex-column">
                            <span>Voucheri eestikeelne tekst</span>
                            <span>(nt. TASUTA KOHV)*</span>
                        </span>
                    </Form.Label>
                    <div className="col-4">
                        <Form.Control
                            value={form.campaign.voucherLabelEt ?? ''}
                            onChange={(e: any) => setForm({
                                ...form,
                                campaign: {
                                    ...form.campaign,
                                    voucherLabelEt: e.target.value
                                }
                            })}
                            minLength={4}
                            type="text"
                            required
                        />
                        <Form.Control.Feedback className={'text-right'} type="invalid">Eestikeelne voucheri tekst on kohustuslik!</Form.Control.Feedback>
                    </div>
                </Form.Group>
                <Form.Group className="row">
                    <Form.Label
                        className="col-3"
                    >
                        <span className="text-nowrap d-flex flex-column">
                            <span>Voucheri inglisekeelne tekst</span>
                            <span>(nt. FREE COFFEE)</span>
                        </span>
                    </Form.Label>
                    <div className="col-4">
                        <Form.Control
                            value={form.campaign.voucherLabelEn ?? ''}
                            onChange={(e: any) => setForm({
                                ...form,
                                campaign: {
                                    ...form.campaign,
                                    voucherLabelEn: e.target.value
                                }
                            })}
                            minLength={4}
                            type="text"
                        />
                    </div>
                </Form.Group>
                <FileUploadFormGroup 
                    acceptedMimeTypes={ACCEPTED_MIME_TYPES}
                    isInvalid={uploadFormIsInvalid}
                    uploadedFile={form.imageEt}
                    label="Eestikeelne pilt*"
                    onImageDrop={(file) => onImageDrop(file, LanguageOption.ET)}
                    onImageRemove={() => onImageRemove(LanguageOption.ET)}
                    getFile={() => getImageFile(LanguageOption.ET)}
                />
                <FileUploadFormGroup 
                    acceptedMimeTypes={ACCEPTED_MIME_TYPES}
                    uploadedFile={form.imageEn}
                    label="Inglisekeelne pilt"
                    onImageDrop={(file) => onImageDrop(file, LanguageOption.EN)}
                    onImageRemove={() => onImageRemove(LanguageOption.EN)}
                    getFile={() => getImageFile(LanguageOption.EN)}
                />
                <Form.Group 
                    controlId={'form-loyalty-campaign-type'} 
                    className="row"
                >
                    <Form.Label 
                        className="col-3"
                    >
                        <span className="text-nowrap">Ikoon*</span>
                    </Form.Label>
                    <div className="col">
                        <div className="d-flex align-items-center justify-content-around flex-wrap">
                            {
                                CAMPAIGN_ICON_OPTIONS.map((icon, idx) => {
                                    const isActive = icon === form.icon;
                                    return (
                                        <div 
                                            key={idx} 
                                            className={`campaign-icon ${isActive ? 'campaign-icon--active' : ''}`}
                                            onClick={() => setForm({
                                                ...form,
                                                icon
                                            })}
                                        >
                                            <i className={`icon--42 icon__${icon} ${isActive ? `icon__${icon}--active` : ''}`}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            !form.icon && form.campaign.active && form.campaign.type !== LoyaltyCampaignType.BONUS_MONEY && form.validated && (
                                <div className='loyalty-campaign__icon_dropdown--invalid'>Ikoon on Kohustuslik</div>
                            )
                        }
                    </div>
                    <div className="col-3"/>
                </Form.Group>   
            </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex flex-row align-items-end justify-content-end flex-wrap mb-1">
            <Button 
                variant="info"
                onClick={() => props.handleModalHide()}
                type="button"
                className={"d-flex align-items-center"}
            >
                <span className='px-2'>Tühista</span>
            </Button>
            <Button variant="primary" onClick={validateSubmit} type="button" disabled={btnIsDisabled}
                    className="d-flex align-items-center">
                {
                    !saving &&
                    <span className='px-2'>Salvesta</span>
                }
                {
                    saving &&
                    <>
                        <span className='px-2'>Salvestamine…</span>
                        <Spinner size={"sm"} animation={"border"}/>
                    </>
                }
            </Button>
        </Modal.Footer>
      </Modal>    
    );
}
