import React, {PureComponent} from 'react';
import {ModalType} from "../common/common-models";
import {EnergyPackage, EnergyPackageSortFields, EnergyPackagesState,} from "../components/EnergyPackages/models";
import {EditEnergyPackageModal} from "../components/EnergyPackages/modals/EditEnergyPackageModal";
import {EnergyPackagesSearchBar} from "../components/EnergyPackages/EnergyPackagesSearchBar";
import {EnergyPackagesTable} from "../components/EnergyPackages/EnergyPackagesTable";
import {IPageResponse, SortDirection} from "../common/interfaces/IPageResponse";
import LoyaltyGroupService from "../services/loyalty/energy-group.service";
import EnergyPackagesService from "../components/EnergyPackages/energy-packages.service";
import {EnergyGroup} from "../components/EnergyGroups/models";
import EnergyGroupService from '../services/loyalty/energy-group.service';
import { toast } from 'react-toastify';


export default class EnergyPackages extends PureComponent<any, EnergyPackagesState> {
    constructor(props: any) {
        super(props);
        this.state = {
            energyPackages: null,
            energyGroups: null,
            pageRequest: {
                page: 0,
                size: 20,
                sortDirection: SortDirection.ASC,
                sortField: EnergyPackageSortFields.ID,
                searchQuery: null
            },
            isEditModalVisible: false,
            isDeleteModalVisible: false,
            modalType: ModalType.CREATE,
            isSyncing: false
        };

        this.props.onLoadValidatePage();
    }

    getLoyaltyPackagesAndGroups() {
        LoyaltyGroupService.getAllEnergyGroups().then((response: Array<EnergyGroup>) => {
            this.setState({
                energyGroups: response
            });
        });

        EnergyPackagesService.getAllEnergyPackages(this.state.pageRequest)
            .then((response: IPageResponse<EnergyPackage>) => {
                this.setState({
                    energyPackages: response
                });
            });
    }


    onPageChange = (targetPage: number) => {
        if (this.state.pageRequest.page + 1 !== targetPage) {
            this.setState({
                pageRequest: {
                    ...this.state.pageRequest,
                    page: targetPage - 1
                }
            }, () => this.getLoyaltyPackagesAndGroups());
        }
    };

    openEnergyPackageModal = (modalType: ModalType, energyPackage?: EnergyPackage) => {
        this.setState({
            isEditModalVisible: true,
            isDeleteModalVisible: false,
            modalType: modalType,
            energyPackage: energyPackage
        })
    };

    openDeleteEnergyPackageModal = (energyPackage: EnergyPackage) => {
        this.setState({
            isEditModalVisible: false,
            isDeleteModalVisible: true,
            energyPackage: energyPackage
        })
    };

    syncEnergyGroups = () => {
        this.setState({isSyncing: true});

        EnergyGroupService.syncEnergyGroups().then(() => {
            toast("Grupid uuendatud!", {
                position: "top-right",
                type: 'success'
            });
        }).catch((error) => {
            console.error(error)

            toast("Gruppide uuendamisel tekkis viga!", {
                position: "top-right",
                type: 'error'
            });
        }).finally(() => this.setState({isSyncing: false}))
    }

    componentDidMount() {
        this.getLoyaltyPackagesAndGroups();
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                        <EnergyPackagesSearchBar 
                            openEnergyPackageModal={this.openEnergyPackageModal}
                            isSyncing={this.state.isSyncing}
                            syncGroups={this.syncEnergyGroups}
                        />
                        {this.state.energyPackages !== null ?
                            <EnergyPackagesTable energyPackages={this.state.energyPackages}
                                                 openEnergyPackageModal={this.openEnergyPackageModal}
                                                 openDeleteEnergyPackageModal={this.openDeleteEnergyPackageModal}
                            /> :
                            null
                        }
                    </div>
                </div>
                {
                    this.state.isEditModalVisible &&
                    <EditEnergyPackageModal
                        handleModalHide={() => {this.setState({isEditModalVisible: false})}}
                        modalType={this.state.modalType}
                        editingEnergyPackage={this.state.energyPackage}
                        loadEnergyPackages={() => this.getLoyaltyPackagesAndGroups()}
                        energyGroups={this.state.energyGroups ?? []}
                    />
                }
            </div>
        );
    }
}
