import AwesomeDebouncePromise from "awesome-debounce-promise";
import { IPageResponse, SortDirection } from "common/interfaces/IPageResponse";
import { LoyaltyCampaignService } from "components/LoyaltyCampaigns/LoyaltyCampaign.service";
import { LoyaltyCampaignsSearchBar } from "components/LoyaltyCampaigns/LoyaltyCampaignsSearchBar";
import { LoyaltyCampaignsTable } from "components/LoyaltyCampaigns/LoyaltyCampaignsTable";
import { UpdateLoyaltyCampaignModal } from "components/LoyaltyCampaigns/modals/UpdateLoyaltyCampaignModal";
import { LoyaltyCampaign, LoyaltyCampaignFilters, LoyaltyCampaignSortFields, LoyaltyCampaignsState } from "components/LoyaltyCampaigns/models";
import React from "react";
import { PureComponent } from "react";
import { toast } from "react-toastify";

const searchApi = AwesomeDebouncePromise(
  request => LoyaltyCampaignService.getLoyaltyCampaigns(request),
  300
);

export default class LoyaltyCampaigns extends PureComponent<any, LoyaltyCampaignsState> {
  constructor(props: any) {
      super(props);
      this.state = {
        campaigns: null,
        pageRequest: {
          page: 0,
          size: 20,
          sortDirection: SortDirection.ASC,
          sortField: LoyaltyCampaignSortFields.ID,
          searchQuery: null
        },
        filters: { },
        isRequesting: false,
        isModalVisible: false,
        isSyncing: false
      };
      this.props.onLoadValidatePage();
  }

  getLoyaltyCampaigns(){
    LoyaltyCampaignService.getLoyaltyCampaigns(this.state.pageRequest).then((response: IPageResponse<LoyaltyCampaign>) => {
      this.setState({
        campaigns: response
      });
    })
  }

  handleFilterChange = (change: LoyaltyCampaignFilters) => {
    this.setState({
        campaigns: null,
        pageRequest: {
            page: 0,
            size: this.state.pageRequest.size,
            sortDirection: this.state.pageRequest.sortDirection,
            sortField: this.state.pageRequest.sortField
        },
        filters: {
            ...this.state.filters,
            ...change
        },
        isRequesting: false,
    }, () => {
        this.searchLoyaltyCampaigns({
            ...this.state.pageRequest,
            ...this.state.filters,
            page: 0
        });
    });
  };

  searchLoyaltyCampaigns = (request: any) => {
    this.setState({
        isRequesting: true
    });
    searchApi(request).then((response: IPageResponse<LoyaltyCampaign>) => {
        this.setState({
            campaigns: response, 
            isRequesting: false
        });
    })
  };

  onPageChange = (targetPage: number) => {
    const page = targetPage - 1;

    this.setState({
        pageRequest: {
            ...this.state.pageRequest,
            page
        }
    }, () => this.searchLoyaltyCampaigns({
      ...this.state.pageRequest,
      ...this.state.filters,
      page,
    }));
  };

  handleSortClick = (sortField: LoyaltyCampaignSortFields) => {
    let sortDirection = SortDirection.ASC;
    if (this.state.pageRequest.sortField.match(sortField)) {
        sortDirection = this.state.pageRequest.sortDirection === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC;
    }
    this.setState({
        pageRequest: {
            ...this.state.pageRequest,
            sortDirection,
            sortField
        }
    }, () => this.onPageChange(1))
  };

  onUpdateLoyaltyCampaign = (campaign: LoyaltyCampaign) => {
    this.setState({ 
        isModalVisible: true,
        activeCampaign: campaign
    })
  }

  onSyncCampaigns = async () => {
    this.setState({ isSyncing: true });
    try {
      await LoyaltyCampaignService.syncCampaigns();
      this.onPageChange(1);
      toast("Kampaaniad uuendatud!", {
        position: "top-right",
        type: 'success'
      });
    } catch(e) {
      console.error(e);
      toast("Kampaaniate uuendamine ebaõnnestus", {
        position: "top-right",
        type: 'error'
      })
    } finally {
      this.setState({ isSyncing: false });
    }
  }

  componentDidMount(): void {
    this.getLoyaltyCampaigns();
  }

  render() {
    return (
        <div className="container">
            <div className="row">
                <div className="col">
                <LoyaltyCampaignsSearchBar
                    filters={this.state.filters}
                    onFilter={this.handleFilterChange}
                    isSyncing={this.state.isSyncing}
                    syncCampaigns={this.onSyncCampaigns}
                />
                  {
                    this.state.campaigns !== null ?
                      <LoyaltyCampaignsTable
                          onPageChange={this.onPageChange}
                          onSort={this.handleSortClick}
                          sortDirection={this.state.pageRequest.sortDirection}
                          sortField={this.state.pageRequest.sortField}
                          campaigns={this.state.campaigns}
                          updateLoyaltyCampaign={this.onUpdateLoyaltyCampaign}
                      /> :
                    null
                  }
                </div>
            </div>
            {(this.state.isModalVisible && this.state.activeCampaign) ? 
              <UpdateLoyaltyCampaignModal
                handleModalHide={() => {
                  this.setState({ isModalVisible: false });
                }}
                campaign={this.state.activeCampaign}
                onSuccess={() => this.searchLoyaltyCampaigns({
                  ...this.state.pageRequest,
                  ...this.state.filters
                })}
              />
            : null }
        </div>
    );
  }
}
