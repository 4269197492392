import { IPageResponse } from './../../common/interfaces/IPageResponse';
import axios from 'axios';
import {apiService} from "../../services/api.service";
import {convertToPageable, extractData, handleResponse} from "../../helpers/apiResponseHelper";
import {ITermsAndCondition, ITermsAndConditionsForm} from "./models";


export const TermsAndConditionsService = {
    getTermsAndConditions: getTermsAndConditions,
    postTermsAndConditions: postTermsAndConditions,
    putTermsAndConditions: putTermsAndConditions,
};

async function getTermsAndConditions(pageRequest?: any): Promise<IPageResponse<ITermsAndCondition>> {
    return axios.get(apiService.getApiUrl() + '/terms-and-conditions/admin', {
        params: pageRequest
    }).then(handleResponse).then(convertToPageable);
}

async function postTermsAndConditions(request: ITermsAndConditionsForm): Promise<ITermsAndCondition> {
    return axios.post(apiService.getApiUrl() + '/terms-and-conditions/admin',
        {
            ...request
        }).then(handleResponse).then(extractData);
}

async function putTermsAndConditions(termsAndConditionsId: number, request: ITermsAndConditionsForm) {
    return axios.put(apiService.getApiUrl() + '/terms-and-conditions/admin/' + termsAndConditionsId,
        {
            ...request
        }).then(handleResponse).then(extractData);
}
