import axios from "axios";
import {apiService} from "../../services/api.service";
import {convertToPageable, extractData, handleResponse} from "../../helpers/apiResponseHelper";
import {EnergyPackageForm} from "./models";


async function getAllEnergyPackages(pageRequest: any) {
    return axios.get(apiService.getApiUrl() + '/energy-packages/admin', {
        params: pageRequest
    }).then(handleResponse).then(convertToPageable)
}

async function postEnergyPackage(request: EnergyPackageForm) {
    return axios.post(apiService.getApiUrl() + '/energy-packages/admin',
        {
            ...request
        }).then(handleResponse).then(extractData);
}

async function patchEnergyPackage(energyPackageId: number, request: EnergyPackageForm) {
    return axios.patch(apiService.getApiUrl() + '/energy-packages/admin/' + energyPackageId,
        {
            ...request
        }).then(handleResponse).then(extractData);
}

const EnergyPackagesService = {
    getAllEnergyPackages,
    patchEnergyPackage,
    postEnergyPackage,
}

export default EnergyPackagesService;
