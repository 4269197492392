import {ModalType} from "../../common/common-models";
import {IPageResponse} from "../../common/interfaces/IPageResponse";
import {IPageRequest} from "../../common/interfaces/IPageRequest";
import {EnergyGroup} from "../EnergyGroups/models";

export interface EnergyPackagesState {
    energyPackages: IPageResponse<EnergyPackage> | null;
    energyGroups: Array<EnergyGroup> | null;
    pageRequest: IPageRequest<EnergyPackageSortFields>;
    isEditModalVisible: boolean;
    isDeleteModalVisible: boolean;
    isSyncing: boolean;
    modalType: ModalType;
    energyPackage?: EnergyPackage;
}

export enum EnergyPackageSortFields {
    ID = "ID",
}

export interface EnergyPackage {
    id: number;
    nameEt: string;
    nameEn?: string;
    nameRu?: string;
    monthlyFeeEuros?: number;
    freeElectricityContractsCount?: number;
    monthlyVolumeLimit?: number;
    priceCeiling: boolean;
    homeChargerDiscount?: number;
    homeChargerDiscountCode?: string;
    smartCharging: boolean;
    publicTariffDiscount?: number;
    timeBasedTariffDiscount?: number;
    dateModified: string;
    primaryGroup: EnergyGroup;
    fallbackGroup?: EnergyGroup;
    orderNumber: number;
    isDefault: boolean;
    highlighted: boolean;
}

export interface EnergyPackagesSearchBarProps {
    openEnergyPackageModal: (modalType: ModalType, energyPackage?: EnergyPackage) => void;
    syncGroups: () => void;
    isSyncing: boolean;
}

export interface EnergyPackageTableProps {
    energyPackages: IPageResponse<EnergyPackage>;
    openEnergyPackageModal: (modalType: ModalType, energyPackage?: EnergyPackage) => void;
    openDeleteEnergyPackageModal: (energyPackage: EnergyPackage) => void;
}

export interface EnergyPackageModalProps {
    modalType: ModalType;
    handleModalHide: () => void;
    editingEnergyPackage?: EnergyPackage;
    loadEnergyPackages: () => void;
    energyGroups?: Array<EnergyGroup>;
}

export interface EnergyPackageForm {
    nameEt: string;
    nameEn?: string;
    nameRu?: string;
    monthlyFeeEuros?: number;
    freeElectricityContractsCount?: number;
    monthlyVolumeLimit?: number;
    priceCeiling: boolean;
    homeChargerDiscount?: number;
    homeChargerDiscountCode?: string;
    smartCharging: boolean;
    publicTariffDiscount?: number;
    timeBasedTariffDiscount?: number;
    primaryGroup?: EnergyGroup;
    fallbackGroup?: EnergyGroup;
    orderNumber: number;
    isDefault: boolean;
    highlighted: boolean;
}
